<template>
  <div class="calendar">
    <CalendarHeader
      :selected-date="selectedMonth"
      @dateSelected="changeMonth"
    />
    <CalendarWeekdays />
    <div class="calendar-days" :style="firstDay">
      <CalendarDays
        v-for="day in days"
        :key="day.date"
        :day="day"
        :selectedDay="selectedDay"
        :availability="availability"
        :data-date="day.date"
        @action="selectDay($event)"
      />
    </div>
  </div>
</template>

<script>
import CalendarDays from "@/components/Calendar/CalendarDays.vue";
import CalendarHeader from "@/components/Calendar/CalendarHeader.vue";
import CalendarWeekdays from "@/components/Calendar/CalendarWeekdays.vue";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(weekday);

export default {
  name: "Calendar",
  components: {
    CalendarHeader,
    CalendarWeekdays,
    CalendarDays,
  },
  props: {
    availability: { type: Array },
    selectedTimeDay: { type: String },
    defaultSelectedDate: { type: String },
  },
  data() {
    return {
      selectedDay: {},
      selectedMonth: dayjs(),
      firstDayOfTheMonthWeekday: "",
    };
  },
  computed: {
    firstDay() {
      return {
        "--col-start":
          this.firstDayOfTheMonthWeekday == 0
            ? 7
            : this.firstDayOfTheMonthWeekday,
      };
    },
    days() {
      return [...this.currentMonthDays];
    },
    month() {
      return Number(this.selectedMonth.format("M"));
    },
    year() {
      return Number(this.selectedMonth.format("YYYY"));
    },
    numberOfDaysInMonth() {
      return this.selectedMonth.daysInMonth();
    },
    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((day, index) => {
        return {
          date: dayjs(`${this.year}-${this.month}-${index + 1}`).format(
            "YYYY-MM-DD"
          ),
          isCurrentMonth: true,
        };
      });
    },
    dealerFromStore() {
      return this.$store.state.dealerSelected;
    },
  },
  created() {
    if (dayjs(this.selectedTimeDay).isValid()) {
      this.selectedMonth = dayjs(this.selectedTimeDay);
      this.selectedDay = dayjs(this.selectedTimeDay);
    } else {
      this.selectedMonth = dayjs();
    }

    this.firstDayOfTheMonthWeekday = this.getWeekday(
      this.selectedMonth.startOf("M").toDate()
    );

    if (this.defaultSelectedDate && dayjs(this.defaultSelectedDate).isValid()) {
      this.selectedDay = dayjs(this.defaultSelectedDate);
    }
  },
  methods: {
    changeMonth(newSelectedMonth) {
      this.selectedDay = null;
      this.selectedMonth = newSelectedMonth;
      this.firstDayOfTheMonthWeekday = this.getWeekday(
        this.selectedMonth.startOf("M").toDate() // Usar selectedMonth em vez de newSelectedMonth
      );
      this.$emit('month-changed');
    },
    getWeekday(date) {
      return dayjs(date).weekday();
    },
    selectDay(event) {
      this.selectedDay = event.day;
      const date = dayjs(this.selectedDay).format("YYYY-MM-DD");
      this.$emit("action", date);
    },
  },
  watch: {
    dealerFromStore(newDealer) {
      this.defaultSelectedDate = newDealer;
    },
    selectedTimeDay(newVal) {
      if(dayjs(newVal).isValid())
        this.selectedMonth = dayjs(newVal);
      else 
        this.selectedMonth = dayjs();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Calendar";
</style>
