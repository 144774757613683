<template>
  <div class="calendar-days">
    <li v-for="weekday in weekdays" :key="weekday">
      <span class="sm-text">{{ weekday }}</span>
    </li>
  </div>
</template>

<script>
import { translateString } from "@/functions/functions.js";

export default {
  name: "CalendarWeekdays",
  data() {
    return {
      weekdays: translateString("weekdays"),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/Calendar";
</style>
