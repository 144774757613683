<template>
  <!-- eslint-disable-next-line vue/valid-template-root -->
  <li
    :class="isPast + notAvailable + isSelected + 'calendar-day'"
    @click="action($event)"
  >
    <span class="selection"></span>
    <span class="sm-text">{{ label }}</span>
  </li>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "CalendarDays",
  props: {
    day: {
      type: Object,
      required: true,
    },
    selectedDay: {
      type: Object,
    },
    availability: { type: Array },
  },
  data() {
    return {
      isPast: dayjs().startOf("d") > dayjs(this.day.date) ? "disabled " : "",
      label: dayjs(this.day.date).format("D"),
      notAvailable: "",
    };
  },
  computed: {
    isSelected() {
      return dayjs(this.selectedDay)
        .startOf("d")
        .isSame(dayjs(this.day.date).startOf("d"))
        ? "selected "
        : "";
    },
  },
  created() {
    let hasAvailability = true;
    if (this.availability.length > 0) {
      if (this.isPast == "") {
        hasAvailability = this.availability.find((day) => {
          return dayjs(this.day.date).isSame(day.day);
        });
      }
    } else {
      const date = dayjs(this.day.date);
      const today = dayjs().startOf("day");
      const tomorrow = dayjs().startOf("day").add(1, "day");
      const afterTomorrow = dayjs().startOf("day").add(2, "day");
      const afterAfterTomorrow = dayjs().startOf("day").add(3, "day");
      const weekday = dayjs(this.day.date).day();
      if (
        dayjs(date).isSame(today) ||
        dayjs(date).isSame(tomorrow) ||
        dayjs(date).isSame(afterTomorrow) ||
        dayjs(date).isSame(afterAfterTomorrow) ||
        weekday == 0 ||
        weekday == 6
      ) {
        hasAvailability = false;
      }
    }
    this.notAvailable = !hasAvailability ? "disabled " : "";
  },
  methods: {
    action(event) {
      this.$emit("action", { target: event.target, day: dayjs(this.day.date) });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Calendar";
</style>
